import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { send, SERVER_HOST, useFibaMsgBox, useLoading } from '../../components';
import { Dialog } from "@progress/kendo-react-dialogs";
import { INFORMATIONSTATUS } from '../../constants/data.cont';
import FibaButton from '../../components/button/FibaButton/FibaButton';
import { useAuth } from '../../contexts/AuthContext';

const InformationDetail = ({ modalState, data, closeModal }) => {

    const { t } = useTranslation()
    const { error, confirm, success } = useFibaMsgBox();
    const { showLoading } = useLoading()
    const [datatmp, setData] = useState({})
    const { auth } = useAuth()

    const getDetail = async () => {
        const res = await send("lo010110", { id: data.id }, showLoading);
        if (res.status === 'error') {
            error(res.message)
        } else {
            for (let index = 0; index < INFORMATIONSTATUS.length; index++) {
                const element = INFORMATIONSTATUS[index];
                if ((element.value + '') === (res.statusid + '')) {
                    res.status = t(element.name);
                    break;
                }
            }
            setData(res);
        }
    }

    const sendCofirmRequest = async () => {
        const isconfirmtxn = await confirm(t('confirminformation'));
        if (isconfirmtxn) {
            const res = await send("lo010114", { id: data.id }, showLoading);
            if (res.status === 'error') {
                error(res.message)
            } else {
                success(t('success'))
                closeModal()
            }
        }
    }
    
    const correctionRequest = async () => {
        const isconfirmtxn = await confirm(t('correctioninformation'));
        if (isconfirmtxn) {
            const res = await send("lo010115", { id: data.id }, showLoading);
            if (res.status === 'error') {
                error(res.message)
            } else {
                success(t('success'))
                closeModal()
            }
        }
    }

    const ShowDetail = () => {
        const details = [];

        for (const key in datatmp.data) {
            if (Object.prototype.hasOwnProperty.call(datatmp.data, key)) {
                const element = datatmp.data[key];
                details.push(
                    <React.Fragment key={key}>
                        <div className='sm:text-right font-bold'>
                            {t(key)}:
                        </div>
                        <div>{element}</div>
                    </React.Fragment>
                );
            }
        }

        return <>{details}</>;
    };

    useEffect(() => {
        getDetail()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {modalState && (
                <Dialog
                    title={t("more")}
                    onClose={closeModal}
                    width={"90vw"}
                    height={"80vh"}
                >
                    {(datatmp.statusid === 3 && auth.user.role < 3) && <div className='flex justify-end mb-2'>
                        <FibaButton onClick={sendCofirmRequest} themeColor='secondary'>
                            {t('confirm')}
                        </FibaButton>
                    </div>}

                    {(datatmp.statusid === 1 && auth.user.role === 3) && <div className='flex justify-end mb-2'>
                        <FibaButton onClick={correctionRequest} themeColor='secondary'>
                            {t('correction')}
                        </FibaButton>
                    </div>}
                    <div className='grid sm:grid-cols-2 gap-2'>
                        <div className='sm:text-right font-bold'>
                            {t('created_at')}:
                        </div>
                        <div>{datatmp.created_at}</div>
                        <div className='sm:text-right font-bold'>
                            {t('allocated')}:
                        </div>
                        <div>{datatmp.shiidwerlesen}</div>
                        <div className='sm:text-right font-bold'>
                            {t('status')}:
                        </div>
                        <div>{datatmp.status}</div>
                        <ShowDetail />
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 p-4'>
                        <div className='font-bold'>
                            {t('problems')}
                            <hr className='my-2' />
                            <img src={`${SERVER_HOST}/api/v1/image?id=${datatmp.photo}&type=GPA`} alt="" />
                        </div>
                        {
                            datatmp.shiidwerlesenphoto && <div>
                                <div className='font-bold'>
                                    {t('decided')}
                                </div>
                                <hr className='my-2'/>
                                <img src={`${SERVER_HOST}/api/v1/image?id=${datatmp.shiidwerlesenphoto}&type=GPA`} alt="" />
                            </div>
                        }

                    </div>
                </Dialog>
            )}
        </>
    )
}

export default InformationDetail