import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FibaTextArea, Form, send, SERVER_HOST, useFibaMsgBox, useLoading, WebCamera } from '../../components'
import { Field } from '@progress/kendo-react-form'
import { Dialog } from "@progress/kendo-react-dialogs";
import { XCircleIcon } from '@heroicons/react/24/outline';

const InformationDecide = ({ modalState, closeModal, initdata }) => {

    const { t } = useTranslation()
    const [data, setData] = useState({})
    const { error } = useFibaMsgBox();
    const { showLoading } = useLoading()
    const [imgid, setSteImgid] = useState();
    const [step, setStep] = useState(1);

    const getDetail = async () => {
        const res = await send("lo010110", { id: initdata.id }, showLoading);
        if (res.status === 'error') {
            error(res.message)
        } else {
            setData(res);
        }
    }

    const ShowDetail = () => {
        const details = [];

        for (const key in data.data) {
            if (Object.prototype.hasOwnProperty.call(data.data, key)) {
                const element = data.data[key];
                details.push(
                    <React.Fragment key={key}>
                        <div className='sm:text-right font-bold'>
                            {t(key)}:
                        </div>
                        <div>{element}</div>
                    </React.Fragment>
                );
            }
        }

        return <>{details}</>;
    };

    const responseCallBack = (data) => {
        setSteImgid(data)
        if (data) {
            setStep(step + 1)
        }
    }

    const remove = async (e) => {
        e.preventDefault()
        setSteImgid(null)
        setStep(1)
    }

    useEffect(() => {
        getDetail()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {modalState && (
                <Dialog
                    title={t("decide")}
                    onClose={closeModal}
                    width={"90vw"}
                    height={"auto"}
                >
                    <Form
                        read="lo010113"
                        afterRedirect={false}
                        onFinish={closeModal ?? null}
                        userSendData={{photo: imgid}}
                        initialValues={{
                            id: initdata.id,
                            userid: initdata.shiidwerlesen,
                        }}
                    >
                        <div className='grid sm:grid-cols-2 gap-2'>
                            <div className='sm:text-right font-bold'>
                                {t('created_at')}:
                            </div>
                            <div>{data.created_at}</div>
                            <ShowDetail />
                        </div>
                        <div className='mt-2'>
                            <div className={`flex justify-center mt-8 mb-6`}>
                                {/* <div>
                                    <Field
                                        name={'photo'}
                                        component={FibaPhotoInput}
                                        label={t('photo')}
                                        required={true}
                                        placeholder={t('photo')}
                                        width="200px"
                                        height="220px"
                                    />
                                </div> */}
                                {step === 1 && <WebCamera setPhotoId={responseCallBack} />}
                                {step === 2 && <>
                                    <img
                                        src={`${SERVER_HOST}/api/v1/image?id=${imgid}&type=GPA`}
                                        alt="upload"
                                        className='w-full hover:scale-105 transition duration-300 ease-in-out'
                                        loading='lazy'
                                    />
                                    <div className='relative'>
                                        <div className="absolute -top-3 -right-3 cursor-pointer">
                                            <XCircleIcon className='w-6 h-6 fill-white' onClick={remove} />
                                        </div>
                                    </div>
                                </>}
                            </div>

                            <Field
                                name={'description'}
                                component={FibaTextArea}
                                label={t('description')}
                                rows="3"
                                required={true}
                                placeholder={t('description')}
                            />
                        </div>
                    </Form>
                </Dialog>
            )}
        </>
    )
}

export default InformationDecide